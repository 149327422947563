<template>
  <div class="bbr-user-membership--deactivate d-flex align-center">
    <v-row class="d-flex align-center">
      <v-col cols="12" md="7">
        <h4>Deactivate Membership</h4>
        <div>
          <p v-if="!isDeactivated">
            Once you deactivate this membership, there is no going back. Please
            be certain.
          </p>
          <p v-else>
            <span v-if="willBeDeactivated">
              This membership will be deactivated on
            </span>
            <span v-else>
              This membership has been deactivated last
            </span>
            <span class="font-weight-bold">
              {{ deactivatedAt | formatDate('MMMM D, YYYY HH:mm a') }}
            </span>
          </p>
        </div>
      </v-col>

      <v-col cols="12" md="5" class="text-right">
        <div v-if="!isDeactivated">
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="220px"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="form.deactivated_at"
                :append-icon="icons.calendar"
                label="Deactivate date/time"
                clearable
                readonly
                outlined
                flat
                :error-messages="form.$getError('deactivated_at')"
              />
            </template>
            <v-date-picker
              min="2020-01-01"
              v-model="form.deactivated_at"
              @input="dateMenu1 = false"
            />
          </v-menu>
        </div>

        <v-btn
          v-if="!isDeactivated"
          class="px-10"
          color="primary"
          :loading="loading"
          :disabled="isDeactivated"
          @click="deactivate"
        >
          Deactivate Membership
        </v-btn>

        <div v-else>
          <v-btn
            class="px-10"
            color="primary"
            :loading="loading"
            @click="reactivate"
          >
            Reactivate Membership
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDeactivation" @close="confirmed" />
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapState } from 'vuex'
import Form from '@/utils/form'
import moment from 'moment-timezone'

export default {
  name: 'DeactivateUserMembership',

  components: {
    ConfirmDialog,
  },

  props: {
    userId: {
      type: [Number, String],
      required: true,
    },

    membershipId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,

      icons: {
        calendar: mdiCalendar,
      },

      dateConfig: {
        type: 'string',
        mask: 'iso',
      },

      tzFormat: (date) => {
        return date ? moment(date).format('lll') : null
      },

      dateMenu1: false,

      form: new Form({
        deactivated_at: null,
      }),
    }
  },

  computed: {
    ...mapState({
      membership: (state) => state.userProfile.selectedMembership,
    }),

    status() {
      return this.membership ? this.membership.user_subscription.status : null
    },

    isCompleted() {
      if (this.membership && !this.membership.user_subscription.starts_at) {
        return false
      }

      return this.status === 'completed' || false
    },

    completedAt() {
      return this.isCompleted ? this.membership.user_subscription.ends_at : null
    },

    isDeactivated() {
      return this.membership
        ? !!this.membership.user_subscription.deactivated_at
        : false
    },

    willBeDeactivated() {
      return (
        this.membership &&
        this.membership.user_subscription.deactivated_at &&
        !this.membership.user_subscription.is_deactivated
      )
    },

    programType() {
      return this.membership ? this.membership.type : null
    },

    programTypeText() {
      return this.programType === 1 ? 'program' : 'challenge'
    },

    deactivatedAt() {
      return this.membership
        ? this.membership.user_subscription.deactivated_at
        : false
    },

    isDefaultProgram() {
      return this.membership
        ? this.membership.user_subscription.is_default
        : false
    },
  },

  methods: {
    ...mapActions({
      deactivateUserMembership: 'userProfile/deactivateUserMembership',
      reactivateUserMembership: 'userProfile/reactivateUserMembership',
    }),

    confirmed(confirmed) {
      return confirmed
    },

    async deactivate() {
      const confirm = await this.$refs.confirmDeactivation.open(
        'Deactivate Membership',
        'Are you sure you want to deactivate this membership?'
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.deactivateUserMembership({
          userId: this.$route.params.id,
          userSubscriptionId: this.membershipId,
          deactivated_at: this.form.deactivated_at,
        })

        setTimeout(() => {
          this.loading = false
          this.$emit('deactivated', true)
        }, 900)
      } catch (err) {
        this.loading = false

        this.form.$timeout(() => {
          if (err.response && err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }

          this.form.$busy = false
        })
      }
    },

    async reactivate() {
      const confirm = await this.$refs.confirmDeactivation.open(
        'Reactivate Membership',
        'Are you sure you want to reactivate this membership?'
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.reactivateUserMembership({
          userId: this.$route.params.id,
          userSubscriptionId: this.membershipId,
        })

        setTimeout(() => {
          this.loading = false
          this.$emit('reactivated', true)
        }, 900)
      } catch (err) {
        this.loading = false

        this.form.$timeout(() => {
          if (err.response && err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }

          this.form.$busy = false
        })
      }
    },
  },
}
</script>
